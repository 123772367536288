import api from '../api'
export default function setup() {
  //Interceptor request
  api.interceptors.request.use(
    function(config) {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    function(err) {
      return Promise.reject(err)
    }
  )

  //Interceptor response
  api.interceptors.response.use(
    function(response) {
      // Do something with response data
      return response
    },
    function(error) {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        window.location = 'login'
      }
      if (error.response.status === 402) {
        console.log(error.response)
      }
    }
  )
}
