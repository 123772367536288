import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import { VBTogglePlugin } from "bootstrap-vue";

import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import { extend, localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("es", es);

//interceptor
import interceptors from "@/helpers/interceptors";
interceptors();

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VBTogglePlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

window.io = require("socket.io-client");

import "vue2-datepicker/index.css";
import linkify from "vue-linkify";
Vue.directive("linkified", linkify);

import "vue2-datepicker/index.css";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
