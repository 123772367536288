import * as currencyFormatter from 'currency-formatter'
import Swal from 'sweetalert2'

export const currencyFormat = (amount, code = 'CRC') => {
  return currencyFormatter.format(amount, {
    code: code
  })
}

export const confirmAlert = (title, message, cancelButton = true) => {
  return Swal.fire({
    title: title,
    text: message,
    icon: 'warning',
    showCancelButton: cancelButton,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, continuar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    customClass: {
      confirmButton: 'ml-2 bg-success border-0',
    },
  })
}

export const identificationInLetter = code => {
  const identifications = [
    { value: '01', text: 'Cédula fisica' },
    { value: '02', text: 'Cédula juridica' },
    { value: '03', text: 'DIMEX' },
    { value: '04', text: 'NITE' },
  ]
  const i = identifications.find(i => i.value === code)
  return i ? i.text : 'S/T'
}

export const firstLetterEachWord = words => {
  const matches = words.match(/\b(\w)/g)
  let acronym = ''
  if (matches) {
    acronym = matches.join('')
  }

  return acronym
}

export const perPageBaseOptions = [10, 20, 30, 40, 50, 100]

export const perPageBaseRegisters = 10

export const lbsToKgs = lbs => parseFloat(lbs) * 0.45359237

export const downloadBlob = (data, nameFile) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', nameFile)
  document.body.appendChild(link)
  link.click()
}
