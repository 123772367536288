import api from "@/api";

class UserService {
  static getUsers(page, search) {
    const params = { page: page, perPage: search.perPage };
    if (search.term) {
      params.search = search.term;
    }

    return api({
      method: "get",
      url: "users",
      params: params
    });
  }

  static storeUser(data) {
    return api({
      method: "post",
      data: data,
      url: "users"
    });
  }

  static updateUser(data) {
    return api({
      method: "put",
      data: data,
      url: "users"
    });
  }

  static deleteUser(data) {
    return api({
      method: "delete",
      data: data,
      url: "users"
    });
  }

  static activeDeactivateUser(data) {
    return api({
      method: "post",
      data: data,
      url: "active-deactivate-user"
    });
  }

  static updateProfilePhoto(data) {
    return api({
      method: "post",
      data,
      url: "update-profile-photo"
    });
  }

  static getRoles() {
    return api({
      url: "roles"
    });
  }

  static getRolesAndPermissions() {
    return api({
      url: "roles-and-permissions"
    });
  }

  static changeStatusModulePermission(data) {
    return api({
      url: "change-module-permission-status",
      data,
      method: "post"
    });
  }

  static changeStatusSubModulePermission(data) {
    return api({
      url: "change-submodule-permission-status",
      data,
      method: "post"
    });
  }
}

export default UserService;
