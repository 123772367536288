<template>
  <validation-observer ref="formValidation">
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent
    >
      <div class="row">
        <div class="col">
          <b-form-group
            label="Nombre del servicio *"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Nombre"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="service.name"
                :state="errors.length > 0 ? false:null"
                name="Nombre"
                placeholder="Nombre del servicio"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Precio *"
            label-for="price"
          >
            <validation-provider
              #default="{ errors }"
              name="Precio"
              :rules="{
                required: true,
                regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
              }"
            >
              <b-form-input
                id="price"
                v-model="service.price"
                :state="errors.length > 0 ? false:null"
                name="Precio"
                placeholder="Precio del servicio"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Código Cabys"
            label-for="cabys_code"
          >
            <v-select
              v-model="service.cabys_code"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cabys_codes"
              :clearable="false"
              class="per-page-selector mr-1"
              @search="searchCabysCodes"
              :reduce="code => code.code"
              :filterable="false"
            >
              <template #selected-option-container="{ option }">
                <div class="vs__selected">{{ option.code }} - {{ option.name }}</div>
              </template>
              <template #option="{ code, name }">
                <strong>{{ code }} - {{ name }}</strong>
              </template>
            </v-select>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Iva"
            label-for="iva"
          >
            <div>
              <v-select
                v-model="service.tax"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="taxes"
                :clearable="false"
                class="per-page-selector mr-1"
                label="name"
              />
            </div>

          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Descripción del servicio"
            label-for="description"
          >
            <b-form-textarea
              v-model="service.description"
              id="description"
              placeholder="Descripción del servicio"
              rows="2"
              max-rows="5"
            />
          </b-form-group>
        </div>
      </div>

      <div class="text-right">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="gradient-primary"
            block
            @click="storeService"
          >
            Guardar
          </b-button>
        </b-overlay>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'

//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import serviceClass from '@/services/services.service'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['service', 'edit'],
  components: {
    vSelect,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required,
      overlay: false,
      cabys_codes: []
    }
  },
  computed: {
    taxes() {
      return this.$store.state.app.taxes
    }
  },
  methods: {
    async storeService() {
      try {
        this.$refs.formValidation.validate().then(async success => {
          if (success) {
            this.overlay = true
            if (this.edit) {
              try {
                const { data:res } = await serviceClass.updateService(this.service)
                if (res.success) {
                  this.$emit('formAction', { data: res.data, type: 'updated' })
                  this.toast(res.message)
                  this.$store.dispatch('app/getGeneralData')
                } else {
                  this.swal("Ha ocurrido un error", res.message)
                }
              } catch (err) {
                this.swal("Ha ocurrido un error interno", err)
              }
            } else {
              try {
                const { data:res } = await serviceClass.storeService(this.service)
                if (res.success) {
                  this.$emit('formAction', { data: res.data, type: 'created' })
                  this.toast(res.message)
                  this.$store.dispatch('app/getGeneralData')
                } else {
                  this.swal("Ha ocurrido un error", res.message)
                }
              } catch (err) {
                this.swal("Ha ocurrido un error interno", err)
              }
            }
            this.overlay = false
          }
        })
      } catch (error) {
        this.overlay = false
      }
    },
    async searchCabysCodes(search, loading) {
      try {

        if(search.length) {
          loading(true)
          const { data:res } = await serviceClass.searchCabysCodes(search)
          this.cabys_codes = res.data
          loading(false)
        }

      } catch (error) {
        console.log(error)
      }
    },
    toast(title, variant = 'success', icon = 'CheckCircleIcon') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
    swal(title, content = '') {
      Swal.fire({
        title: title,
        text: content,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }
  }
}
</script>
