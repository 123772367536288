import api from '@/api'

class ServiceClass {

  static getServices(page, search) {
    const params = { page: page, perPage: search.perPage }

    if (search.term) params.search = search.term

    return api({
      url: 'services',
      params: params,
    })
  }

  static changeStatusService(data) {
    return api({
      url: 'change-status-service',
      data,
      method: 'put'
    })
  }

  static storeService(data) {
    return api({
      method: 'post',
      data: data,
      url: 'services',
    })
  }

  static updateService(data) {
    return api({
      method: 'put',
      data,
      url: 'services',
    })
  }

  static deleteService(data) {
    return api({
      method: 'delete',
      data: data,
      url: 'services',
    })
  }

  static searchCabysCodes(search) {
    return api({
      url: 'search-cabys-code',
      params: { search }
    })
  }

  static getServiceCabysGeneral() {
    return api({
      url: 'get-service-cabys-general'
    })
  }

}

export default ServiceClass
