<template>
  <ValidationObserver ref="formValidation">
    <b-form @submit.prevent>
      <div class="row">
        <div class="col">
          <!-- name -->
          <b-form-group label="Nombre" label-for="name">
            <ValidationProvider
              name="Nombre"
              rules="required"
              #default="{ errors }"
            >
              <b-form-input
                id="name"
                v-model="client.name"
                name="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="col">
          <!-- surname -->
          <b-form-group label="Apellido" label-for="surname">
            <b-form-input id="surname" v-model="client.surname" />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- email -->
          <b-form-group label="Email" label-for="email">
            <ValidationProvider
              name="Correo"
              rules="required|email"
              #default="{ errors }"
            >
              <b-form-input
                id="email"
                v-model="client.email"
                name="email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Tipo de identificación" label-for="address">
            <ValidationProvider
              name="Tipo de identificación"
              rules="required"
              #default="{ errors }"
            >
              <b-form-select
                v-model="client.identification_type"
                :options="identificationTypes"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- identification_number -->
          <b-form-group
            label="Número de identificacón"
            label-for="identification_number"
          >
            <ValidationProvider
              name="Número de identificación"
              rules="required|integer"
              #default="{ errors }"
            >
              <b-form-input
                id="identification_number"
                v-model="client.identification_number"
                name="identification_number"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Provincia" label-for="province">
            <ValidationProvider
              name="Provincia"
              rules="required"
              #default="{ errors }"
            >
              <b-form-select
                @change="getCantonsC()"
                value-key="id"
                v-model="client.province"
                name="province"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option
                  v-for="province in $store.state.app.provinces"
                  :value="province"
                  :key="province.id"
                >
                  {{ province.name }}
                </b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group label="Canton" label-for="canton">
            <ValidationProvider
              name="canton"
              rules="required"
              #default="{ errors }"
            >
              <b-form-select
                @change="getDistrictsC()"
                value-key="id"
                v-model="client.canton"
                name="canton"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option
                  v-for="c in $store.state.app.cantons"
                  :value="c"
                  :key="c.id"
                >
                  {{ c.name }}
                </b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Distrito" label-for="district">
            <ValidationProvider
              name="Distrito"
              rules="required"
              #default="{ errors }"
            >
              <b-form-select
                @change="getBarriosC()"
                value-key="id"
                v-model="client.district"
                name="district"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option
                  v-for="d in $store.state.app.districts"
                  :value="d"
                  :key="d.id"
                >
                  {{ d.name }}
                </b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group label="Barrio" label-for="barrio">
            <ValidationProvider
              name="barrio"
              rules="required"
              #default="{ errors }"
            >
              <b-form-select
                value-key="id"
                v-model="client.barrio"
                name="barrio"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select-option
                  v-for="b in $store.state.app.barrios"
                  :value="b"
                  :key="b.id"
                >
                  {{ b.name }}
                </b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div class="col">
          <!-- address -->
          <b-form-group label="Dirección" label-for="address">
            <ValidationProvider
              name="Dirección"
              rules="required"
              #default="{ errors }"
            >
              <b-form-input
                id="address"
                v-model="client.address"
                name="address"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group label="Teléfono" label-for="phone">
            <ValidationProvider
              name="Teléfono"
              rules="required|integer"
              #default="{ errors }"
            >
              <b-form-input
                id="phone"
                v-model="client.phone"
                name="phone"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </div>
      </div>

      <!-- submit button -->
      <div class="text-center">
        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary" @click="storeClient">
            <feather-icon icon="SaveIcon" size="16" />
            Guardar
          </b-button>
        </b-overlay>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//Validation forms
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
//Services
import clientService from "@/services/client.service";

import { mapActions } from "vuex";

import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BOverlay
} from "bootstrap-vue";

export default {
  props: ["client"],
  components: {
    BFormInput,
    BButton,
    vSelect,
    BFormGroup,
    BForm,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  data() {
    return {
      required,
      email,
      identificationTypes: [
        { value: "01", text: "Cédula fisica" },
        { value: "02", text: "Cédula juridica" },
        { value: "03", text: "DIMEX" },
        { value: "04", text: "NITE" }
      ],
      loading: false
    };
  },
  methods: {
    ...mapActions({
      getProvinces: "app/getProvinces",
      getCantons: "app/getCantons",
      getDistricts: "app/getDistricts",
      getBarrios: "app/getBarrios"
    }),

    getCantonsC() {
      this.getCantons({
        province: this.client.province.number
      });
    },
    getDistrictsC() {
      this.getDistricts({
        province: this.client.province.number,
        canton: this.client.canton.number
      });
    },
    getBarriosC() {
      this.getBarrios({
        province: this.client.province.number,
        canton: this.client.canton.number,
        district: this.client.district.number
      });
    },

    async storeClient() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          try {
            this.loading = true;
            const { data: res } = await clientService.storeClient(this.client);

            if (!res.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.message,
                  icon: "",
                  variant: "danger"
                }
              });
              this.loading = false;
              return false;
            }

            this.$emit("formAction");

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
            this.loading = false;
          } catch (error) {
            toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: "CheckIcon",
                variant: "warning"
              }
            });
            this.loading = false;
          }
        }
      });
    }
  },
  mounted() {
    this.getProvinces();
  }
};
</script>
