<template>
  <validation-observer ref="formValidation">
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent
    >
      <div class="row">
        <div class="col">
          <b-form-group
            label="Nombre *"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Nombre"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="distributor.name"
                :state="errors.length > 0 ? false:null"
                name="Nombre"
                placeholder="Nombre del repartidor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Apellido *"
            label-for="surname"
          >
            <validation-provider
              #default="{ errors }"
              name="Apellido"
              rules="required"
            >
              <b-form-input
                id="surname"
                v-model="distributor.surname"
                :state="errors.length > 0 ? false:null"
                name="Apellido"
                placeholder="Apellido del repartidor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Teléfono *"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="Telefono"
              rules="required|integer"
            >
              <b-form-input
                id="email"
                v-model="distributor.phone"
                :state="errors.length > 0 ? false:null"
                name="Telefono"
                placeholder="Telefono"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Correo electronico *"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="Correo electronico"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="distributor.email"
                :state="errors.length > 0 ? false:null"
                name="Correo electronico"
                placeholder="Correo electronico"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group
            :label="`Contraseña de acceso a la aplicación ${edit ? '' : '*' }`"
            label-for="password"
          >
            <validation-provider
              #default="{ errors }"
              name="Contraseña"
              :rules="`${edit ? '' : 'required' }|min:8`"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  v-model="distributor.password"
                  :state="errors.length > 0 ? false:null"
                  name="Contraseña"
                  :type="passwordFieldType"
                  placeholder="Contraseña de acceso a la aplicación"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="text-right">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="gradient-primary"
            block
            @click="storeDistributor"
          >
            Guardar
          </b-button>
        </b-overlay>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'

//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import distributorService from '@/services/distributors.service'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  props: ['distributor', 'edit'],
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      integer,
      min,
      overlay: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async storeDistributor() {
      try {
        this.$refs.formValidation.validate().then(async success => {
          if (success) {
            this.overlay = true
            if (this.edit) {
              try {
                const { data:res } = await distributorService.updateDistributor(this.distributor)
                if (res.success) {
                  this.$emit('formAction', { data: res.data, type: 'updated' })
                  this.toast(res.message)
                  this.$store.dispatch('app/getGeneralData')
                } else {
                  this.swal("Ha ocurrido un error", res.message)
                }
              } catch (err) {
                this.swal("Ha ocurrido un error interno", err)
              }
            } else {
              try {
                const { data:res } = await distributorService.storeDistributor(this.distributor)
                if (res.success) {
                  this.$emit('formAction', { data: res.data, type: 'created' })
                  this.toast(res.message)
                  this.$store.dispatch('app/getGeneralData')
                } else {
                  this.swal("Ha ocurrido un error", res.message)
                }
              } catch (err) {
                this.swal("Ha ocurrido un error interno", err)
              }
            }
            this.overlay = false
          }
        })
      } catch (error) {
        this.overlay = false
      }
    },
    toast(title, variant = 'success', icon = 'CheckCircleIcon') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
    swal(title, content = '') {
      Swal.fire({
        title: title,
        text: content,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    }
  }
}
</script>
