<template>
  <validation-observer ref="formValidation">
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent
    >

      <div class="row mb-2">
        <div class="col">
          <b-form-checkbox
            v-model="order.scheduled"
            class="custom-control-primary"
            :disabled="edit"
          >
            Programar fecha
          </b-form-checkbox>
        </div>
      </div>

      <div class="row mb-2" v-if="order.scheduled">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <b-form-group
            label="Fecha de la orden"
          >
            <validation-provider
              #default="{ errors }"
              name="Fecha"
              rules="required"
            >
              <date-picker
                range-separator="-"
                v-model="order.date"
                :default-value="order.date"
                append-to-body
                lang="es"
                format="YYYY-MM-DD"
                style="width: 100%"
                clearable
                name="Fecha"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <b-form-group
            label="Hora de la orden"
          >
            <validation-provider
              #default="{ errors }"
              name="Hora"
              rules="required"
            >
              <b-form-timepicker
                name="Hora"
                v-model="order.hour"
                locale="es"
                :state="errors.length > 0 ? false:null"
                v-bind="label_locale || {}"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col d-flex mb-2">
          <validation-provider
            #default="{ errors }"
            name="Tipo de transporte"
            rules="required"
          >
            <span>Tipo de transporte</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="order.vehicle"
                name="Tipo de transporte"
                value="moto"
              >
                Moto
              </b-form-radio>
              <b-form-radio
                v-model="order.vehicle"
                name="Tipo de transporte"
                value="carro"
              >
                Carro
              </b-form-radio>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <b-form-group
            label="Cliente *"
          >
            <validation-provider
              #default="{ errors }"
              name="Cliente"
              rules="required"
            >
              <v-select
                style="width: 100%"
                v-model="order.client"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state.app.clients"
                :clearable="false"
                :state="errors.length > 0 ? false:null"
                :reduce="client => client.id"
                class="per-page-selector mr-1"
                label="full_name"
                name="Cliente"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <b-form-group
            label="Repartidor"
          >
            <v-select
              style="width: 100%"
              v-model="order.distributor.user"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="$store.state.app.distributors"
              :clearable="false"
              :reduce="distributor => distributor.id"
              class="per-page-selector mr-1"
              label="full_name"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <b-form-group
            label="Dirección de recogida"
            label-for="pickup_address"
          >
            <validation-provider
              #default="{ errors }"
              name="Dirección recogida"
              rules="required"
            >
              <b-form-textarea
                v-model="order.pickup_address"
                id="pickup_address"
                placeholder="Dirección de recogida"
                rows="1"
                :state="errors.length > 0 ? false:null"
                max-rows="4"
                name="Dirección recogida"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <b-form-group
            label="Dirección de entrega *"
            label-for="pickup_delivery"
          >
            <validation-provider
              #default="{ errors }"
              name="Dirección entrega"
              rules="required"
            >
              <b-form-textarea
                v-model="order.delivery_address"
                id="pickup_delivery"
                placeholder="Dirección de entrega"
                rows="1"
                :state="errors.length > 0 ? false:null"
                max-rows="4"
                name="Dirección entrega"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <hr>

      <!--SERVICIOS-->
      <div ref="form" class="repeater-form">

        <h4>Agregar servicios a la orden</h4>
        <b-row
          v-for="(item, index) in order.services"
          :id="`item-${index}`"
          :key="index"
          ref="row"
        >
          <!-- Item Name -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              :name="`Servicio ${index+1}`"
              rules="required"
            >
              <b-form-group
                label="Selecciona un servicio *"
                label-for="item-name"
              >
                <v-select
                  v-model="item.service"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.state.app.services"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  class="per-page-selector mr-1"
                  label="name"
                  :name="`Servicio ${index+1}`"
                  @input="amountTotalInServices()"
                  :disabled="edit && order.paid"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
            v-if="order.services.length > 1"
          >
            <b-button
              :disabled="edit && order.paid"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </div>
      <div class="row">
        <div class="col d-flex justify-content-between">
          <b-button
            :disabled="edit && order.paid"
            variant="primary"
            @click="repeateAgain"
            class="mb-2"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Agregar servicio</span>
          </b-button>
          <b v-if="edit && order.paid" class="text-danger">
            No se pueden editar los servicios de una orden pagada
          </b>

          <h4 v-if="!edit" class="text-primary">{{ currencyFormat(amount_total_in_services) }}</h4>

        </div>
      </div>
      <!--FIN SERVICIOS-->

      <hr>

      <div class="row">
        <div class="col">
          <b-form-group
            label="Descripción de la orden *"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="Descripción"
              rules="required"
            >
              <b-form-textarea
                v-model="order.description"
                id="description"
                placeholder="Descripción de la orden"
                rows="1"
                :state="errors.length > 0 ? false:null"
                max-rows="4"
                name="Descripción"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row" v-if="!edit">
        <div class="col">
          <b-form-group
            label="Agrega una nota (opcional)"
            label-for="note"
          >
            <b-form-textarea
              v-model="order.note"
              id="note"
              placeholder="Agrega una nota"
              rows="1"
              max-rows="4"
            />
          </b-form-group>
        </div>
      </div>

      <b-form-checkbox
        v-model="order.electronic_invoice"
        class="custom-control-primary"
      >
        Generar factura electronica
      </b-form-checkbox>

      <div class="text-right">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="gradient-primary"
            block
            @click="storeOrder"
          >
            Guardar
          </b-button>
        </b-overlay>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BOverlay,
  BFormTextarea,
  BRow,
  BCol,
  BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'

//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import OrderService from '@/services/order.service'
import { currencyFormat } from '@/helpers'
import DatePicker from 'vue2-datepicker';
import { BFormTimepicker } from 'bootstrap-vue'
import moment from 'moment'

export default {
  mixins: [heightTransition],
  props: ['order', 'edit'],
  components: {
    BFormRadio,
    BFormTimepicker,
    DatePicker,
    BRow,
    BCol,
    vSelect,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      disabledDates: {
        to: new Date(Date.now())
      },
      label_locale: {
        labelNoTimeSelected: 'Seleccione una hora',
        labelCloseButton: 'Cerrar'
      },
      required,
      overlay: false,
      amount_total_in_services: 0
    }
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today/* || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000)*/;
    },
    storeOrder() {
      try {
        this.$refs.formValidation.validate().then(async success => {
          if (success) {
            this.overlay = true
            if (this.edit) {
              try {
                const { data:res } = await OrderService.updateOrder(this.order)
                if (res.success) {
                  this.$emit('formAction', { data: res.data, type: 'updated' })
                  this.toast(res.message)
                } else {
                  this.swal("Ha ocurrido un error", res.message)
                }
              } catch (err) {
                this.swal("Ha ocurrido un error interno", err)
              }
            } else {
              try {
                const { data:res } = await OrderService.storeOrder(this.order)
                if (res.success) {
                  this.$emit('formAction', { data: res.data, type: 'created' })
                  this.toast(res.message)
                } else {
                  this.swal("Ha ocurrido un error", res.message)
                }
              } catch (err) {
                this.swal("Ha ocurrido un error interno", err)
              }
            }
            this.overlay = false
          }
        })
      } catch (error) {
        this.overlay = false
      }
    },
    toast(title, variant = 'success', icon = 'CheckCircleIcon') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
    swal(title, content = '') {
      Swal.fire({
        title: title,
        text: content,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    amountTotalInServices() {
      let total = []
      this.order.services.forEach(i => {
        total.push(parseFloat(i.service.total))
      })
      this.amount_total_in_services = total.reduce(function(a, b) { return a + b } )
    },
    currencyFormat(amount) {
      return currencyFormat(amount)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    repeateAgain() {
      this.order.services.push({
        service: ''
      })
/*
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })*/
    },
    removeItem(index) {
      this.order.services.splice(index, 1)
      //this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.amountTotalInServices()
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
}
</style>
