import { $themeBreakpoints } from "@themeConfig";
import UtilitiesService from "@/services/utilities.service";

//import menu from "../menu";

export default {
  namespaced: true,
  state: {
    menu: [
      {
        title: "Dashboard",
        route: "dashboard",
        icon: "MonitorIcon",
        key: "dashboard"
      },
      {
        title: "Servicios",
        route: "services",
        icon: "ListIcon",
        roles: ["admin"],
        key: "services"
      },
      {
        title: "Clientes",
        route: "clients",
        icon: "UsersIcon",
        roles: ["admin"],
        key: "clients"
      },
      {
        title: "Ordenes",
        route: "orders",
        icon: "BoxIcon",
        roles: ["admin"],
        key: "orders"
      },
      {
        title: "Usuarios",
        route: "users",
        icon: "UserIcon",
        roles: ["admin"],
        key: "users"
      },
      {
        title: "Repartidores",
        route: "distributors",
        icon: "UsersIcon",
        key: "distributors"
      },
      {
        title: "Facturación",
        route: "invoices",
        icon: "DollarSignIcon",
        key: "invoices"
      },
      {
        title: "Notas",
        route: "notes",
        icon: "ListIcon",
        key: "notes"
      },
      {
        title: "Recep. Documentos",
        route: "document-receptions",
        icon: "ListIcon",
        key: "receptions"
      },
      {
        title: "Reportes",
        route: "reports",
        icon: "FileTextIcon",
        key: "reports",
        children: [
          {
            title: "Depositos",
            route: "report-deposits",
            key: "show_deposits_report"
          },
          {
            title: "Facturas",
            route: "report-invoices",
            key: "show_invoices_report"
          },
          {
            title: "Notas",
            route: "report-notes",
            key: "show_notes_report"
          },
          {
            title: "Recep. Documentos",
            route: "report-receptions",
            key: "show_receptions_report"
          },
          {
            title: "Dinero en repartidor",
            route: "report-money-distributor",
            key: "show_money_distributor"
          },
          {
            title: "Estado de cuenta",
            route: "clients-account-states",
            key: "show_report_clients_account_states"
          }
        ]
      },
      {
        title: "Permisos",
        route: "permissions",
        icon: "LockIcon",
        key: "permissions"
      },
      {
        title: "Configuraciones",
        route: "configurations",
        icon: "ToolIcon",
        key: "configurations"
      }
    ],
    windowWidth: 0,
    shallShowOverlay: false,
    user: null,
    provinces: [],
    cantons: [],
    districts: [],
    barrios: [],
    clients: [],
    services: [],
    distributors: [],
    taxes: [],
    payment_methods: [],
    payment_forms: [],
    currencies: [{ name: "Colones", value: 1 }],
    economic_activities: [],
    items_menu: [],
    permissions: [],
    company_info: {
      name: '',
      phone: '',
      address: ''
    },
    /*variables usadas para abrir formularios con el boton Nuevo del sidebar*/
    action: "",
    action_name: ""
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    }
  },
  mutations: {
    SELECT_ACTION_BUTTON_SIDEBAR(state, data) {
      state.action = data.action;
      state.action_name = data.actionName;
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    UPDATE_USER(state, user) {
      state.user = user;
    },
    updateProvinces(state, payload) {
      state.provinces = payload.provinces;
    },
    updateCantons(state, payload) {
      state.cantons = payload.cantons;
    },
    updateDistricts(state, payload) {
      state.districts = payload.districts;
    },
    updateBarrios(state, payload) {
      state.barrios = payload.barrios;
    },
    SET_GENERAL_DATA(state, payload) {
      state.clients = payload.clients;
      state.distributors = payload.distributors;
      state.services = payload.services;
      state.taxes = payload.taxes;
      state.payment_methods = payload.payment_methods;
      state.payment_forms = payload.payment_forms;
      state.economic_activities = payload.economic_activities;
      state.company_info = payload.company_info
    }
  },
  actions: {
    update_user({ commit, state }, data) {
      commit("UPDATE_USER", data.user);
      console.log("82", state.menu);

      if (data.user.role != "admin") {
        state.permissions = data.permissions.map(i => {
          return i.key;
        });

        let keys_menu = data.menu.map(i => {
          return i.key;
        });

        let submenus = data.menu
          .filter(i => i.items.length)
          .map(i => {
            return i.items;
          });

        let keys_submenus = [];
        submenus.forEach(i => {
          i.forEach(j => {
            keys_submenus.push(j.key);
          });
        });

        state.items_menu = state.menu.filter(i => keys_menu.includes(i.key));

        state.items_menu.forEach((i, index) => {
          if (i.hasOwnProperty("children")) {
            i.children.forEach((j, indexx) => {
              if (!keys_submenus.includes(j.key))
                state.items_menu[index].children.splice(indexx, 1);
            });
          }
        });

        let permissions_storage = [];
        permissions_storage = data.menu.map(i => {
          return i.key;
        });
        keys_submenus.forEach(i => permissions_storage.push(i));

        localStorage.setItem(
          "permissions",
          JSON.stringify(permissions_storage)
        );
      } else {
        state.items_menu = state.menu;
      }
    },
    getGeneralData({ commit }) {
      return new Promise((resolve, reject) => {
        UtilitiesService.getUtilityData()
          .then(res => {
            commit("SET_GENERAL_DATA", res.data);
            resolve(res.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    getProvinces({ commit, state }) {
      if (state.provinces.length == 0) {
        return new Promise((resolve, reject) => {
          UtilitiesService.getProvinces()
            .then(res => {
              commit("updateProvinces", { provinces: res.data });
              resolve(res.data);
            })
            .catch(() => {
              reject();
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve(state.provinces);
        });
      }
    },
    getCantons({ commit }, payload) {
      let province = payload.province;
      return new Promise((resolve, reject) => {
        UtilitiesService.getCantons(province)
          .then(res => {
            commit("updateCantons", { cantons: res.data });
            resolve(res.data);
          })
          .catch(() => {
            reject("Error");
          });
      });
    },
    getDistricts({ commit }, payload) {
      let province = payload.province;
      let canton = payload.canton;
      return new Promise((resolve, reject) => {
        UtilitiesService.getDistricts(province, canton)
          .then(res => {
            commit("updateDistricts", { districts: res.data });
            resolve(res.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    getBarrios({ commit }, payload) {
      let province = payload.province;
      let canton = payload.canton;
      let district = payload.district;
      return new Promise((resolve, reject) => {
        UtilitiesService.getBarrios(province, canton, district)
          .then(res => {
            commit("updateBarrios", { barrios: res.data });
            resolve(res.data);
          })
          .catch(() => {
            reject();
          });
      });
    }
  }
};
