import api from '@/api'
api.defaults.withCredentials = true
import config from '../config'

class AuthService {
  static register(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`${config.apiUrl}register`, data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response)
        })
    })
  }

  static login(data) {
    return api.post(`${config.apiUrl}login`, data)
  }

  static changePassword(data) {
    return new Promise(resolve => {
      api.post(`${config.apiUrl}change-password`, data).then(res => {
        resolve(res.data)
      })
    })
  }

  static getUser(data) {
    return new Promise(resolve => {
      api.get(`${config.apiUrl}get-user/${data}`).then(res => {
        resolve(res.data)
      })
    })
  }

  static updateInformation(data) {
    return new Promise(resolve => {
      api.post(`${config.apiUrl}update-information`, data).then(res => {
        resolve(res.data)
      })
    })
  }

  static me() {
    return api.post(`${config.apiUrl}me`)
  }

  static updateProfile(data) {
    return api({
      method: 'put',
      data: data,
      url: 'update-profile',
    })
  }

  //Change password from home
  static changePassword(data) {
    return api({
      method: 'put',
      data: data,
      url: 'change-password',
    })
  }

  static sendEmailForgotPassword(data) {
    return api({
      method: 'post',
      data: data,
      url: 'send-email-forgot-password',
    })
  }

  static resetPassword(data) {
    return api({
      method: 'post',
      data: data,
      url: 'reset-password',
    })
  }

  static logout() {
    return api.post(`${config.apiUrl}logout`)
  }
}

export default AuthService
