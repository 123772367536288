<template>
  <div>

    <div v-if="isMouseHovered || !isVerticalMenuCollapsed" style="position: relative">
      <div class="text-center">
        <b-button
          variant="outline-primary"
          pill
          @click="showPopover = !showPopover"
        >
          Nuevo
        </b-button>
      </div>

      <div class="container-options" v-if="showPopover">
        <div class="title bg-primary">
          ¿Qué deseas hacer?
        </div>
        <div class="container-body">
          <b-list-group flush style="color: #272727; cursor: pointer">
            <b-list-group-item
              @click="selectAction('service', 'Servicio')"
              v-if="$route.name != 'services'"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              Nuevo servicio
            </b-list-group-item>
            <b-list-group-item
              @click="selectAction('client', 'Cliente')"
              v-if="$route.name != 'clients'"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              Nuevo cliente
            </b-list-group-item>
            <b-list-group-item
              @click="selectAction('order', 'Orden')"
              v-if="$route.name != 'orders'"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              Nueva orden
            </b-list-group-item>
            <b-list-group-item
              @click="selectAction('user', 'Usuario')"
              v-if="$route.name != 'users'"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              Nuevo usuario
            </b-list-group-item>
            <b-list-group-item
              @click="selectAction('dealer', 'Repartidor')"
              v-if="$route.name != 'distributors'"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              Nuevo repartidor
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BButton,
  BListGroup,
  BListGroupItem,
  BSidebar
} from 'bootstrap-vue'

export default {
  props: ['isMouseHovered', 'isVerticalMenuCollapsed'],
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BSidebar
  },
  data() {
    return {
      showPopover: false,
    }
  },
  methods: {
    successAction() {
      this.$store.dispatch('app/getGeneralData')
    },
    selectAction(action, actionName) {
      this.$store.commit('app/SELECT_ACTION_BUTTON_SIDEBAR', {
        action: action,
        actionName: actionName
      })
      this.showPopover = false
    }
  }
}
</script>

<style scoped>
  .container-options {
    background-color: white;
    position: absolute;
    z-index: 100;
    top: 0;
    right: -150px;
    border-radius: 10px;
  }
  .container-body {
    padding: 0 20px 20px 20px;
  }
  .title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    color: white;
    padding: 10px
  }
  .container-options:before {
    content: "\00a0";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 10px;
    left: -15px;
    background-color: #48C9B0;
    clip-path: polygon(100% 0, 0 48%, 100% 100%);
  }
</style>
