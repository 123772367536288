import api from "@/api";

class UtilitiesService {
  static getProvinces() {
    return api.get("provinces");
  }

  static getCantons(province_number) {
    return api.get("cantons", {
      params: {
        province_number: province_number
      }
    });
  }

  static getDistricts(province_number, canton_number) {
    return api.get("districts", {
      params: {
        province_number: province_number,
        canton_number: canton_number
      }
    });
  }

  static getBarrios(province_number, canton_number, district_number) {
    return api.get("barrios", {
      params: {
        province_number: province_number,
        canton_number: canton_number,
        district_number: district_number
      }
    });
  }

  static getStatusesOrder() {
    return api.get("admin-statuses-order");
  }

  static getUtilityData() {
    return api.get("general-data");
  }

  static getCompanyInfo() {
    return api.get("company-info");
  }

  static updateCompanyInfo(data) {
    return api({
      url: "update-company-info",
      method: "post",
      data
    });
  }

  static getNotifications() {
    return api({
      url: "notifications-admin"
    });
  }

  static allNotificationsRead(data) {
    return api({
      url: "mark-all-notificacion-read",
      method: "post",
      data
    });
  }

  static getConsecutiveNote(note_type) {
    return api.get(`get-consecutive-note/${note_type}`);
  }

  //get tax conditions
  static getTaxConditions() {
    return api.get(`get-tax-conditions`);
  }

  static getExpenseCategories() {
    return api.get(`expense-categories`);
  }

  //Store expense categoy
  static storeExpenseCategory(expense_category) {
    return api({
      method: "post",
      url: "store-expense-category",
      data: expense_category
    });
  }

  //Download XML by key50digits
  static downloadXml(key50digits, document_type, folder) {
    return api.get(`download-xml/${key50digits}/${document_type}/${folder}`);
  }

  static getSubjectsAndContentsEmail() {
    return api.get(`get-subjects-contents-email`);
  }

  //Send document email
  static sendDocumentEmail(data) {
    return api({
      method: "post",
      url: "send-document-email",
      data: data
    });
  }
}

export default UtilitiesService;
