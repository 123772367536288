import axios from 'axios'
import config from '../config'

const HTTP = axios.create({
  withCredentials: true,
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})
HTTP.defaults.withCredentials = true
export default HTTP
