var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"disabled":_vm.edit},model:{value:(_vm.order.scheduled),callback:function ($$v) {_vm.$set(_vm.order, "scheduled", $$v)},expression:"order.scheduled"}},[_vm._v(" Programar fecha ")])],1)]),(_vm.order.scheduled)?_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Fecha de la orden"}},[_c('validation-provider',{attrs:{"name":"Fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"range-separator":"-","default-value":_vm.order.date,"append-to-body":"","lang":"es","format":"YYYY-MM-DD","clearable":"","name":"Fecha","disabled-date":_vm.disabledBeforeTodayAndAfterAWeek},model:{value:(_vm.order.date),callback:function ($$v) {_vm.$set(_vm.order, "date", $$v)},expression:"order.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3569078986)})],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Hora de la orden"}},[_c('validation-provider',{attrs:{"name":"Hora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',_vm._b({attrs:{"name":"Hora","locale":"es","state":errors.length > 0 ? false:null},model:{value:(_vm.order.hour),callback:function ($$v) {_vm.$set(_vm.order, "hour", $$v)},expression:"order.hour"}},'b-form-timepicker',_vm.label_locale || {},false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1332507507)})],1)],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex mb-2"},[_c('validation-provider',{attrs:{"name":"Tipo de transporte","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',[_vm._v("Tipo de transporte")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"Tipo de transporte","value":"moto"},model:{value:(_vm.order.vehicle),callback:function ($$v) {_vm.$set(_vm.order, "vehicle", $$v)},expression:"order.vehicle"}},[_vm._v(" Moto ")]),_c('b-form-radio',{attrs:{"name":"Tipo de transporte","value":"carro"},model:{value:(_vm.order.vehicle),callback:function ($$v) {_vm.$set(_vm.order, "vehicle", $$v)},expression:"order.vehicle"}},[_vm._v(" Carro ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Cliente *"}},[_c('validation-provider',{attrs:{"name":"Cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector mr-1",staticStyle:{"width":"100%"},attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.clients,"clearable":false,"state":errors.length > 0 ? false:null,"reduce":function (client) { return client.id; },"label":"full_name","name":"Cliente"},model:{value:(_vm.order.client),callback:function ($$v) {_vm.$set(_vm.order, "client", $$v)},expression:"order.client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Repartidor"}},[_c('v-select',{staticClass:"per-page-selector mr-1",staticStyle:{"width":"100%"},attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.distributors,"clearable":false,"reduce":function (distributor) { return distributor.id; },"label":"full_name"},model:{value:(_vm.order.distributor.user),callback:function ($$v) {_vm.$set(_vm.order.distributor, "user", $$v)},expression:"order.distributor.user"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Dirección de recogida","label-for":"pickup_address"}},[_c('validation-provider',{attrs:{"name":"Dirección recogida","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"pickup_address","placeholder":"Dirección de recogida","rows":"1","state":errors.length > 0 ? false:null,"max-rows":"4","name":"Dirección recogida"},model:{value:(_vm.order.pickup_address),callback:function ($$v) {_vm.$set(_vm.order, "pickup_address", $$v)},expression:"order.pickup_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12 col-12"},[_c('b-form-group',{attrs:{"label":"Dirección de entrega *","label-for":"pickup_delivery"}},[_c('validation-provider',{attrs:{"name":"Dirección entrega","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"pickup_delivery","placeholder":"Dirección de entrega","rows":"1","state":errors.length > 0 ? false:null,"max-rows":"4","name":"Dirección entrega"},model:{value:(_vm.order.delivery_address),callback:function ($$v) {_vm.$set(_vm.order, "delivery_address", $$v)},expression:"order.delivery_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('hr'),_c('div',{ref:"form",staticClass:"repeater-form"},[_c('h4',[_vm._v("Agregar servicios a la orden")]),_vm._l((_vm.order.services),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":("item-" + index)}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":("Servicio " + (index+1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Selecciona un servicio *","label-for":"item-name"}},[_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.$store.state.app.services,"clearable":false,"state":errors.length > 0 ? false:null,"label":"name","name":("Servicio " + (index+1)),"disabled":_vm.edit && _vm.order.paid},on:{"input":function($event){return _vm.amountTotalInServices()}},model:{value:(item.service),callback:function ($$v) {_vm.$set(item, "service", $$v)},expression:"item.service"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.order.services.length > 1)?_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{staticClass:"mt-0 mt-md-2",attrs:{"disabled":_vm.edit && _vm.order.paid,"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-between"},[_c('b-button',{staticClass:"mb-2",attrs:{"disabled":_vm.edit && _vm.order.paid,"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Agregar servicio")])],1),(_vm.edit && _vm.order.paid)?_c('b',{staticClass:"text-danger"},[_vm._v(" No se pueden editar los servicios de una orden pagada ")]):_vm._e(),(!_vm.edit)?_c('h4',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.currencyFormat(_vm.amount_total_in_services)))]):_vm._e()],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Descripción de la orden *","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Descripción de la orden","rows":"1","state":errors.length > 0 ? false:null,"max-rows":"4","name":"Descripción"},model:{value:(_vm.order.description),callback:function ($$v) {_vm.$set(_vm.order, "description", $$v)},expression:"order.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),(!_vm.edit)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Agrega una nota (opcional)","label-for":"note"}},[_c('b-form-textarea',{attrs:{"id":"note","placeholder":"Agrega una nota","rows":"1","max-rows":"4"},model:{value:(_vm.order.note),callback:function ($$v) {_vm.$set(_vm.order, "note", $$v)},expression:"order.note"}})],1)],1)]):_vm._e(),_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.order.electronic_invoice),callback:function ($$v) {_vm.$set(_vm.order, "electronic_invoice", $$v)},expression:"order.electronic_invoice"}},[_vm._v(" Generar factura electronica ")]),_c('div',{staticClass:"text-right"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"gradient-primary","block":""},on:{"click":_vm.storeOrder}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }