import api from '@/api'

class DistributorClass {

  static getDistributors(page, search) {
    const params = { page, perPage: search.perPage }

    if (search.term) params.search = search.term

    return api({
      url: 'distributors',
      params: params,
    })
  }

  static changeStatusDistributor(data) {
    return api({
      url: 'change-status-distributor',
      data,
      method: 'put'
    })
  }

  static storeDistributor(data) {
    return api({
      method: 'post',
      data: data,
      url: 'distributors',
    })
  }

  static updateDistributor(data) {
    return api({
      method: 'put',
      data,
      url: 'distributors',
    })
  }

  static deleteDistributor(data) {
    return api({
      method: 'delete',
      data: data,
      url: 'distributors',
    })
  }

}

export default DistributorClass
