import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import StatusHacienda from "@/components/StatusHacienda";
import ButtonChat from "@/components/ButtonChat";
import AccessQuickButton from "@/components/AccessQuickButton";

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("StatusHacienda", StatusHacienda);
Vue.component("ButtonChat", ButtonChat);
Vue.component("AccessQuickButton", AccessQuickButton);

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
