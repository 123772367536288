<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(storeUser)">

      <!-- name -->
      <b-form-group label="Nombre" label-for="name">
        <ValidationProvider
          name="Nombre"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-input id="name" v-model="user.name" name="name" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- surname -->
      <b-form-group label="Apellido" label-for="surname">
        <ValidationProvider
          name="Apellido"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-input
            id="surname"
            v-model="user.surname"
            name="surname"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- email -->
      <b-form-group label="Email" label-for="email">
        <ValidationProvider
          name="Correo"
          rules="required|email"
          v-slot="{ errors }"
        >
          <b-form-input id="email" v-model="user.email" name="email" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- password -->
      <b-form-group label="Contraseña" label-for="password">
        <ValidationProvider
          name="Contraseña"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-input
            id="password"
            v-model="user.password"
            type="password"
            name="Contraseña"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- phone -->
      <b-form-group label="Teléfono" label-for="phone">
        <b-form-input id="phone" v-model="user.phone" name="phone" />
      </b-form-group>

      <!-- Roles -->
      <b-form-group label="Rol" label-for="role">
        <ValidationProvider
          name="Rol"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-select v-model="user.role" :options="roles" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- submit button -->
      <div class="text-center">
        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
          >
            <feather-icon icon="SaveIcon" size="16" />
            Guardar
          </b-button>
        </b-overlay>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import userService from '@/services/user.service'
import { required, email } from '@validations'

import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BOverlay,
} from 'bootstrap-vue'

export default {
  props: ['user'],
  components: {
    BFormInput,
    BButton,
    BFormGroup,
    BForm,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  data() {
    return {
      required,
      email,
      loading: false,
      roles: [
        { value: 'admin', text: 'Administrador' },
      ]
    }
  },
  methods: {
    async storeUser() {
      try {
            this.loading = true
            const { data: res } = await userService.storeUser(this.user)
            this.loading = false
            if (!res.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.message,
                  icon: 'CheckIcon',
                  variant: 'warning',
                },
              })
              return false
            }

            this.$emit('formAction')

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'CheckIcon',
                variant: 'warning',
              },
            })
            this.loading = false
          }
    }
  }
}
</script>
