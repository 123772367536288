import api from '@/api'

class OrderService {

  static getOrders(page, search) {
    const params = { page: page, perPage: search.perPage }

    if (search.term) params.search = search.term
    if (search.filter) params.filter = search.filter
    if (search.status) params.status = search.status
    if (search.daterange) params.daterange = search.daterange

    return api({
      method: 'get',
      url: 'orders',
      params: params,
    })
  }

  static getCurrentMoneyInDistributor(distributorId) {
    return api({
      url: `current-money-in-distributor/${distributorId}`
    })
  }

  static getOrderDetail(id) {
    return api({
      url: `order-detail/${id}`
    })
  }

  static getOrderDetailForInvoice(id) {
    return api({
      url: `order-detail/${id}`,
      params: { fe: true }
    })
  }

  static changeStatusOrder(data) {
    return api({
      url: 'change-status-order',
      data,
      method: 'post'
    })
  }

  static storeOrder(data) {
    return api({
      method: 'post',
      data,
      url: 'orders',
    })
  }

  static updateOrder(data) {
    return api({
      method: 'put',
      data,
      url: 'orders',
    })
  }

  static assignDistributorToOrder(data) {
    return api({
      method: 'post',
      data,
      url: 'assign-distributor-to-order'
    })
  }

  static removeDistributorToOrder(data) {
    return api({
      method: 'delete',
      data,
      url: 'remove-distributor-to-order'
    })
  }

  static printOrderReceipt(order_id) {
    return api({
      url: 'print-order-receipt',
      params: { order_id },
      responseType: 'blob'
    })
  }

  static storeAddon(data) {
    return api({
      url: 'store-addon-to-order',
      data,
      method: 'post'
    })
  }

  static updateAddons(data) {
    return api({
      url: 'update-addon-to-order',
      data,
      method: 'PUT'
    })
  }

}

export default OrderService
