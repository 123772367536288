var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Nombre *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"Nombre","placeholder":"Nombre del repartidor"},model:{value:(_vm.distributor.name),callback:function ($$v) {_vm.$set(_vm.distributor, "name", $$v)},expression:"distributor.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Apellido *","label-for":"surname"}},[_c('validation-provider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","state":errors.length > 0 ? false:null,"name":"Apellido","placeholder":"Apellido del repartidor"},model:{value:(_vm.distributor.surname),callback:function ($$v) {_vm.$set(_vm.distributor, "surname", $$v)},expression:"distributor.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Teléfono *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Telefono","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"Telefono","placeholder":"Telefono"},model:{value:(_vm.distributor.phone),callback:function ($$v) {_vm.$set(_vm.distributor, "phone", $$v)},expression:"distributor.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Correo electronico *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Correo electronico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"Correo electronico","placeholder":"Correo electronico"},model:{value:(_vm.distributor.email),callback:function ($$v) {_vm.$set(_vm.distributor, "email", $$v)},expression:"distributor.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":("Contraseña de acceso a la aplicación " + (_vm.edit ? '' : '*')),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":((_vm.edit ? '' : 'required') + "|min:8")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false:null,"name":"Contraseña","type":_vm.passwordFieldType,"placeholder":"Contraseña de acceso a la aplicación"},model:{value:(_vm.distributor.password),callback:function ($$v) {_vm.$set(_vm.distributor, "password", $$v)},expression:"distributor.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"text-right"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"gradient-primary","block":""},on:{"click":_vm.storeDistributor}},[_vm._v(" Guardar ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }