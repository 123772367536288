import api from '@/api'

class ClientService {
  static getClients(page, search) {
    const params = { page: page, perPage: search.perPage }
    if (search.term) {
      params.search = search.term
    }

    return api({
      method: 'get',
      url: 'clients',
      params: params,
    })
  }

  static getClient(params) {

    const p = {}

    if (params.per_page) p.per_page = params.per_page
    if (params.page) p.page = params.page
    if (params.query) p.select = params.query
    if (params.daterange) p.daterange = params.daterange
    if (params.status) p.status = params.status.id

    return api({
      url: `client-detail/${params.id}`,
      params: p
    })
  }

  static sendEmails() {
    return api({
      method: 'post',
      url: 'send-emails',
    })
  }

  static storeClient(data) {
    return api({
      method: 'post',
      data: data,
      url: 'clients',
    })
  }

  static storeClientRegister(data) {
    return api({
      method: 'post',
      data: data,
      url: 'register',
    })
  }

  static updateClient(data) {
    return api({
      method: 'put',
      data: data,
      url: 'clients',
    })
  }

  static deleteClient(data) {
    return api({
      method: 'delete',
      data: data,
      url: 'clients',
    })
  }
}

export default ClientService
