import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("dashboard")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/",
      redirect: "clients"
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/confirm-register/:token",
      name: "confirm-register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/clients",
      name: "clients",
      component: () => import("@/views/Clients.vue"),
      meta: {
        pageTitle: "Clientes",
        breadcrumb: [
          {
            text: "Clientes",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("clients")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/client-detail/:id",
      name: "client-detail",
      component: () => import("@/views/ClientDetail.vue"),
      meta: {
        pageTitle: "Clientes",
        breadcrumb: [
          {
            text: "Detalle de cliente",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("clients")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("@/views/Orders.vue"),
      meta: {
        pageTitle: "Ordenes",
        breadcrumb: [
          {
            text: "Ordenes",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("orders")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/order-detail/:id",
      name: "order-detail",
      component: () => import("@/views/OrderDetail.vue"),
      meta: {
        pageTitle: "Ordenes",
        breadcrumb: [
          {
            text: "Detalle de orden",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("orders")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/send-email-forgot-password",
      name: "send-email-forgot-password",
      component: () => import("@/views/SendEmailForgotPassword.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/password/reset/:token",
      name: "reset-password",
      component: () => import("@/views/RecoveryForgotPassword.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/Users.vue"),
      meta: {
        pageTitle: "Usuarios",
        breadcrumb: [
          {
            text: "Usuarios",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("users")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/distributors",
      name: "distributors",
      component: () => import("@/views/Distributors.vue"),
      meta: {
        pageTitle: "Repartidores",
        breadcrumb: [
          {
            text: "Repartidores",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("distributors")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    //Client
    {
      path: "/my-orders",
      name: "my-orders",
      component: () => import("@/views/client/Orders.vue"),
      meta: {
        pageTitle: "Mis Ordenes",
        breadcrumb: [
          {
            text: "Mis Ordenes",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("orders")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/services",
      name: "services",
      component: () => import("@/views/Services.vue"),
      meta: {
        pageTitle: "Servicios",
        breadcrumb: [
          {
            text: "Servicios",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("services")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/invoices",
      name: "invoices",
      component: () => import("@/views/Invoices.vue"),
      meta: {
        pageTitle: "Facturación",
        breadcrumb: [
          {
            text: "Facturas electronicas",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("invoices")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/document-receptions",
      name: "document-receptions",
      component: () => import("@/views/DocumentReceptions.vue"),
      meta: {
        pageTitle: "Notas",
        breadcrumb: [
          {
            text: "Notas de credito y debito",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("receptions")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/notes",
      name: "notes",
      component: () => import("@/views/Notes.vue"),
      meta: {
        pageTitle: "Notas",
        breadcrumb: [
          {
            text: "Notas de credito y debito",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("notes")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/create-note",
      name: "create-note",
      component: () => import("@/views/CreateNote.vue"),
      meta: {
        pageTitle: "Crear nota",
        breadcrumb: [
          {
            text: "Crear nota",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("notes")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/note-detail/:id",
      name: "note-detail",
      component: () => import("@/views/NoteDetail.vue"),
      meta: {
        contentClass: "chat-application",
        pageTitle: "Facturación",
        breadcrumb: [
          {
            text: "Nota",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("notes")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/invoice-detail/:id",
      name: "invoice-detail",
      component: () => import("@/views/InvoiceDetail.vue"),
      meta: {
        contentClass: "chat-application",
        pageTitle: "Facturación",
        breadcrumb: [
          {
            text: "Detalle factura",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("invoices")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/create-invoice",
      name: "create-invoice",
      component: () => import("@/views/CreateInvoice.vue"),
      meta: {
        pageTitle: "Crear factura",
        breadcrumb: [
          {
            text: "Crear factura",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("invoices")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import("@/views/Chat/Chat.vue"),
      meta: {
        contentRenderer: "sidebar-left",
        contentClass: "chat-application"
      }
    },
    {
      path: "/configurations",
      name: "configurations",
      component: () => import("@/views/Configurations.vue"),
      meta: {
        pageTitle: "Configuraciones generales",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("configurations")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/report-deposits",
      name: "report-deposits",
      component: () => import("@/views/Reports/Deposits.vue"),
      meta: {
        pageTitle: "Reporte depositos",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("reports")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/report-money-distributor",
      name: "report-money-distributor",
      component: () => import("@/views/Reports/ReportMoneyDistributor.vue"),
      meta: {
        pageTitle: "Reporte dinero en repartidores",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("reports")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/clients-account-states",
      name: "clients-account-states",
      component: () => import("@/views/Reports/ReportAccountStates.vue"),
      meta: {
        pageTitle: "Estados de cuenta",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("show_report_clients_account_states")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/report-invoices",
      name: "report-invoices",
      component: () => import("@/views/Reports/Invoices.vue"),
      meta: {
        pageTitle: "Reporte facturas",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("reports")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/report-notes",
      name: "report-notes",
      component: () => import("@/views/Reports/Notes.vue"),
      meta: {
        pageTitle: "Reporte Notas",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("reports")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/report-receptions",
      name: "report-receptions",
      component: () => import("@/views/Reports/Receptions.vue"),
      meta: {
        pageTitle: "Reporte Recepción de documentos",
        breadcrumb: [
          {
            text: "",
            active: true
          }
        ]
      },
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("reports")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "/permissions",
      name: "permissions",
      component: () => import("@/views/Permissions"),
      beforeEnter(to, from, next) {
        let permissions = JSON.parse(localStorage.getItem("permissions"));
        let role = localStorage.getItem("role");
        if (role != "admin") {
          if (!permissions) {
            localStorage.clear();
            next({
              name: "login"
            });
          }
          if (permissions.includes("permissions")) {
            next();
          } else {
            next({
              name: "404"
            });
          }
        } else {
          next();
        }
      }
    },
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (
    !token &&
    to.name !== "login" &&
    to.name !== "register" &&
    to.name !== "send-email-forgot-password" &&
    to.name !== "reset-password" &&
    to.name !== "confirm-register"
  ) {
    localStorage.clear();
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
