<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
    <button-chat v-if="$route.name != 'chat' && $route.name != 'login'" />

    <b-sidebar
      :visible="$store.state.app.action != ''"
      @hidden="closeSidebarForm"
      shadow
      bg-variant="white"
      backdrop
      :width="`${$store.state.app.action == 'order' ? '40%' : '30%'}`"
      right
      :title="`Registrar ${$store.state.app.action_name}`"
    >
      <div class="p-2">
        <form-service
          v-if="$store.state.app.action == 'service'"
          :service="service"
          :edit="false"
          @formAction="successAction"
        />

        <form-distributor
          v-if="$store.state.app.action == 'dealer'"
          :distributor="distributor"
          :edit="false"
          @formAction="successAction"
        />

        <form-order
          v-if="$store.state.app.action == 'order'"
          :order="order"
          :edit="false"
          @formAction="successAction"
        />

        <form-client
          v-if="$store.state.app.action == 'client'"
          :client="client"
          @formAction="successAction"
        />

        <form-user
          v-if="$store.state.app.action == 'user'"
          :user="user"
          @formAction="successAction"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { onMounted, watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

import authService from "@/services/auth.service";

import FormService from "@/components/FormService";
import FormDistributor from "@/components/FormDistributor";
import FormOrder from "@/components/FormOrder";
import FormClient from "@/components/FormClient";
import FormUser from "@/components/FormUser";

import { BSidebar } from "bootstrap-vue";

export default {
  components: {
    FormUser,
    FormClient,
    FormOrder,
    FormDistributor,
    FormService,
    BSidebar,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    }
  },
  data() {
    return {
      service: {
        name: "",
        description: "",
        cabys_code: "",
        tax: 0,
        price: "",
        active: true
      },

      distributor: {
        name: "",
        surname: "",
        email: "",
        password: "",
        phone: ""
      },

      order: {
        client: "",
        services: [
          {
            service: ""
          }
        ],
        distributor: {
          user: ""
        },
        pickup_address: "",
        delivery_address: "",
        description: "",
        electronic_invoice: false,
        note: ""
      },

      client: {
        name: "",
        surname: "",
        email: "",
        password: "",
        identification_type: "01",
        identification_number: "",
        address: "",
        phone: "",
        province: null,
        canton: null,
        district: null,
        barrio: null
      },

      user: {
        name: "",
        surname: "",
        email: "",
        password: "",
        phone: "",
        role: "admin"
      }
    };
  },
  methods: {
    successAction() {
      this.user = { ...this.$options.data().user };
      this.client = { ...this.$options.data().client };
      this.distributor = { ...this.$options.data().distributor };
      this.service = { ...this.$options.data().service };

      this.$store.dispatch("app/getGeneralData");
      this.closeSidebarForm();
    },
    closeSidebarForm() {
      this.$store.commit("app/SELECT_ACTION_BUTTON_SIDEBAR", {
        action: "",
        actionName: ""
      });
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark"
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade"
    });

    //Get auth user
    const getUser = async () => {
      try {
        console.log("get user");
        const { data: res } = await authService.me();
        store.dispatch("app/update_user", res.data);
        store.dispatch("app/getGeneralData");
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      const token = localStorage.getItem("token");
      if (token) {
        getUser();
      }
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, val => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses
    };
  }
};
</script>

<style>
.main-menu {
  overflow: unset !important;
}

td.cell.today {
  color: rgb(255, 94, 0) !important;
  font-weight: bold !important;
}
</style>
