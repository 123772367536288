var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Nombre del servicio *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"Nombre","placeholder":"Nombre del servicio"},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Precio *","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"Precio","rules":{
              required: true,
              regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","state":errors.length > 0 ? false:null,"name":"Precio","placeholder":"Precio del servicio"},model:{value:(_vm.service.price),callback:function ($$v) {_vm.$set(_vm.service, "price", $$v)},expression:"service.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Código Cabys","label-for":"cabys_code"}},[_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.cabys_codes,"clearable":false,"reduce":function (code) { return code.code; },"filterable":false},on:{"search":_vm.searchCabysCodes},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
            var option = ref.option;
return [_c('div',{staticClass:"vs__selected"},[_vm._v(_vm._s(option.code)+" - "+_vm._s(option.name))])]}},{key:"option",fn:function(ref){
            var code = ref.code;
            var name = ref.name;
return [_c('strong',[_vm._v(_vm._s(code)+" - "+_vm._s(name))])]}}]),model:{value:(_vm.service.cabys_code),callback:function ($$v) {_vm.$set(_vm.service, "cabys_code", $$v)},expression:"service.cabys_code"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Iva","label-for":"iva"}},[_c('div',[_c('v-select',{staticClass:"per-page-selector mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.taxes,"clearable":false,"label":"name"},model:{value:(_vm.service.tax),callback:function ($$v) {_vm.$set(_vm.service, "tax", $$v)},expression:"service.tax"}})],1)])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"label":"Descripción del servicio","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Descripción del servicio","rows":"2","max-rows":"5"},model:{value:(_vm.service.description),callback:function ($$v) {_vm.$set(_vm.service, "description", $$v)},expression:"service.description"}})],1)],1)]),_c('div',{staticClass:"text-right"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"gradient-primary","block":""},on:{"click":_vm.storeService}},[_vm._v(" Guardar ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }